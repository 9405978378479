import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
   <Layout>
      <SEO title="404: Strona nie istnieje" />
      <div
         style={{
            height: '100vh',
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'center',
         }}
      >
         <h1 style={{ textAlign: 'center' }}>404</h1>
         <p>Upss... ta strona nie istnieje</p>
      </div>
   </Layout>
);

export default NotFoundPage;
